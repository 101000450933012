import classNames from 'classnames'
import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ExperimentNames, isRegistrationClosed, isWaitlistEnabled} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import sc from '../../classes.scss'
import {LinkToPage} from '../../link-to-page'
import {useSettings} from '../../../../hooks/use-settings'
import settingsParams from '../../../../settingsParams'
import s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

export const RsvpButton = ({
  event,
  width,
  height,
  onImage,
  navigateToPage,
  isMobile,
  responsiveButton,
  hidden,
  hasUrl,
  rsvpLabelVisible,
  settings,
  eventRestricted,
  ensureLoginAndNavigateToPage,
}: RsvpButtonProps) => {
  const eventClosed = isRegistrationClosed(event)
  const {experiments} = useExperiments()
  const waitlist = isWaitlistEnabled(event)
  const {get} = useSettings(settings)
  const text =
    eventClosed && !waitlist ? get(settingsParams.registrationClosedButtonText) : get(settingsParams.oneButton)
  const title = rsvpLabelVisible ? get(settingsParams.RSVPTitle) : null

  const style = {
    minWidth: responsiveButton ? '100%' : width,
    minHeight: height,
  }

  const onClick = (e: React.MouseEvent) => {
    if (experiments.enabled(ExperimentNames.FixMemberRestrictionNav)) {
      if (eventRestricted) {
        e.stopPropagation()
      }
      navigateToPage()
    } else {
      if (eventRestricted) {
        navigateToPage()
      } else {
        e.stopPropagation()
        ensureLoginAndNavigateToPage()
      }
    }
  }

  const buttonClassNames = classNames(s.button, sc.textLineHeight, {
    [s.onImageButton]: onImage,
    [s.link]: hasUrl,
  })

  return (
    <div
      id={DH.rsvpButton}
      className={classNames(s.container, {[s.mobile]: isMobile, [s.responsive]: responsiveButton, [s.hidden]: hidden})}
    >
      {title && (
        <div className={classNames(s.title, {[s.onImageTitle]: onImage})} data-hook="ev-rsvp-button-title">
          {title}
        </div>
      )}
      {hasUrl && !hidden ? (
        <LinkToPage event={event} dataHook="ev-rsvp-button" className={buttonClassNames} style={style} allowExternal>
          {text}
        </LinkToPage>
      ) : (
        <button style={style} data-hook="ev-rsvp-button" className={buttonClassNames} onClick={onClick}>
          {text}
        </button>
      )}
    </div>
  )
}
